import React from 'react';

import LoginLayout from '@/features/auth/login';
import withAuthServerSideProps from '@/libs/auth';

import { PageLayout } from '@/layouts/shared';
import { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { fetchMasterData } from '@/features/home/api';
type CountryType = {
  code: string;
  label: string;
  phone: string;
};
export interface CountryProps {
  countries: CountryType[];
}
const LoginPage = ({ countries }: CountryProps) => {
  return <LoginLayout countries={countries} />;
};

export const getServerSideProps = withAuthServerSideProps(
  async ({ locale }: GetServerSidePropsContext) => {
    const { data } = await fetchMasterData();
    const countries = data['countries'];
    return {
      props: {
        countries,
        layout: PageLayout.Basic,
        ...(await serverSideTranslations(locale as string, ['auth'])),
      },
    };
  },
  { isAntiAuth: true, isPublic: false }
);

export default LoginPage;
