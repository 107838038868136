import { useMutation, MutationOptions } from "react-query";

import axios, { AxiosResponse, AxiosErrorResponse } from "@/libs/axios";
import { FormLoginPayload, LoginQueryResponse } from "@/features/auth/login";
import { authApiUrl } from "@/features/auth";

export const useLoginQuery = (
  options?: MutationOptions<AxiosResponse<LoginQueryResponse>, AxiosErrorResponse, FormLoginPayload, unknown>
) => {
  return useMutation((payload: FormLoginPayload) => {
    const { phone, country } = payload;

    return axios.post(authApiUrl.LOGIN + `/${phone}?country=${country}`, {});
  }, options);
};
