export const authApiUrl = {
  SIGNUP: '/api/v1/user/local/signup',
  LOGIN: '/api/v1/user/login',
  LOGIN_BY_PASSWORD: '/api/v1/user/login/password',
  RESET_PASSWORD: '/api/v1/user/password/reset',
  CREATE_PASSWORD: '/api/v1/user/password/create',
  ACTIVATE: '/api/v1/user/activate',
  RESEND_OTP: '/api/v1/user/resend/otp',
};

export const errorCode = {
  OTP_NOT_EXPIRE: 'OTP_NOT_EXPIRE',
};

export interface IPhone {
  phone: string;
  country: string;
};

export type CountryType = {
  code: string;
  label: string;
  phone: string;
};

export type SignUpType = {
  countries: CountryType[];
  code: string;
};