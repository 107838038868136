import { IPhone } from "@/features/auth";

export interface FormLoginPayload extends IPhone {};

export interface LoginQueryResponse extends IPhone {
  id: number;
  otp: string;
};

export const FormLoginDefaultValue = {
  phone: '',
};
