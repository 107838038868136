import React, { Fragment, useEffect, useState } from 'react';

import * as yup from 'yup';
import toast from 'react-hot-toast';

import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import { DefaultCountry, PagePath } from '@/layouts/shared';
import { useTranslation, Trans } from 'next-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Image,
  Button,
  Link,
  Icon,
  ArrowDownIcon,
  Input,
  LockIcon,
} from '@/components';
import MetaTag from '@/components/ui/MetaTag';
import { CountryProps } from '@/pages/login';
import SelectCountry from '@/components/ui/SelectCountry';
import { CountryType } from '@/features/auth';
import { useLoginQuery, FormLoginDefaultValue } from '@/features/auth/login';
import { PHONE_NUMBER } from '../const';
import { useMasterData } from '@/contexts/profile';

const LoginLayout = ({ countries }: CountryProps) => {
  const router = useRouter();
  const masterData = useMasterData();

  // CUSTOM THEME
  const { theme } = masterData ?? {};
  const { logo_url, web_bg_login_left_url, web_bg_login_right_url } =
    theme ?? {};

  const { t } = useTranslation('auth');
  const [defaultCountry, setDefaultCountry] = useState<CountryType | null>(
    countries.find((v) => v.code === DefaultCountry.Vn) || null
  );
  const [isShow, setShow] = useState(false);

  const { mutate, isLoading } = useLoginQuery({
    onSuccess: ({ data }) => {
      const { phone, country } = data;
      if (phone) {
        return router.replace({
          pathname: PagePath.LoginConfirmPage,
          query: {
            phone,
            country: country,
          },
        });
      }
    },
    onError: (error) => {
      if (error.code === 'OTP_NOT_EXPIRE') {
        const { phone, country } = error.data;
        return router.replace({
          pathname: PagePath.LoginConfirmPage,
          query: {
            phone,
            country: country || DefaultCountry.Vn,
          },
        });
      }
      toast.error(error.message);
    },
  });

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: FormLoginDefaultValue,
    resolver: yupResolver(
      yup.object({
        phone: yup.string().required(t('login.message.phone-required')),
      })
    ),
  });

  useEffect(() => {
    const phone = localStorage.getItem(PHONE_NUMBER);
    if (phone) {
      setValue('phone', phone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit((data) => {
    localStorage.setItem(PHONE_NUMBER, data.phone);
    const payload = {
      phone: data.phone,
      country: defaultCountry?.code || DefaultCountry.Vn,
    };
    mutate(payload);
  });

  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  const selectCountry = (country: CountryType) => {
    setShow(false);
    setDefaultCountry(country);
  };

  const onLoginByPassword = () => {
    const phone = watch('phone');
    if (phone) {
      localStorage.setItem(PHONE_NUMBER, phone);
    }
    router.push(PagePath.LoginByPasswordPage);
  };

  if (!masterData) return <></>;

  return (
    <Fragment>
      <MetaTag title={t('login.title')} description={t('login.title')} />
      <div className="tw-h-screen tw-grid tw-grid-cols-1 lg:tw-grid-cols-2">
        <section className="tw-hidden lg:tw-flex tw-justify-center tw-items-center tw-relative">
          <Image
            src={web_bg_login_left_url ?? '/splash.png'}
            alt="splash"
            className="tw-w-full tw-h-full"
            rounded="tw-rounded-none"
            // objectFit='fill'
            // Temporary update for them Tet
            objectFit='cover'
          />
          {/* Temporary update for them Tet */}
          {/* <Image
            src={logo_url ?? '/logo.svg'}
            alt="logo"
            className="tw-w-72 tw-h-40 !tw-absolute tw-inset-0 tw-mx-auto tw-my-auto"
            objectFit="contain"
          /> */}
        </section>
        <section
          className="tw-grid tw-grid-cols-1 tw-justify-center tw-items-center tw-px-4"
          style={{
            backgroundImage: web_bg_login_right_url
              ? `url(${web_bg_login_right_url})`
              : 'unset',
            backgroundSize: 'contain',
          }}
        >
          <div className="tw-flex tw-flex-col tw-w-full sm:tw-w-96 tw-mx-auto">
            <h3 className="tw-text-center tw-text-2xl tw-font-semibold tw-text-primary-main">
              {t('login.title')}
            </h3>
            <div className="tw-mt-8">
              <div className="tw-flex tw-items-center tw-h-[50px] tw-border tw-rounded-full tw-border-primary-main tw-overflow-hidden">
                <div
                  className=" tw-pl-4 tw-pr-2 tw-py-2 tw-flex tw-cursor-pointer tw-w-26"
                  onClick={() => setShow(!isShow)}
                >
                  <div className="tw-flex-1 tw-flex tw-items-center">
                    <Image
                      src={`https://flagcdn.com/w40/${defaultCountry?.code.toLocaleLowerCase()}.png`}
                      alt={defaultCountry?.code || 'country'}
                      className="tw-w-5 tw-h-3  tw-overflow-hidden tw-mr-2"
                      objectFit="cover"
                    />
                    <span className="tw-text-sm">{defaultCountry?.phone}</span>
                  </div>
                  <Icon
                    className="tw-text-primary-main tw-w-5 tw-h-5"
                    icon={<ArrowDownIcon />}
                  />
                </div>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div className="tw-flex tw-flex-1 tw-px-2 focus:tw-outline-none">
                        <Input
                          {...field}
                          className="tw-full tw-flex-1  tw-h-10"
                          containerClassName="tw-flex-1 !tw-border-b-0 tw-border-l tw-border-l-primary-main"
                          onKeyDown={keyPress}
                          placeholder={t('sign-up.form.label.phone')}
                        />
                      </div>
                    );
                  }}
                />
              </div>
              {errors.phone?.message && (
                <div className="tw-text-red-500 tw-text-xs tw-mt-2 tw-px-4">
                  {errors.phone?.message}
                </div>
              )}
            </div>

            <div className="tw-flex tw-flex-col tw-mt-6">
              <Button
                loading={isLoading}
                onClick={onSubmit}
                className="tw-h-[50px] tw-rounded-full tw-mt-2"
              >
                {t('login.btn.login-by-otp')}
              </Button>
            </div>
            <div className="tw-mt-12 tw-text-center">
              <p>{t('login.label.social-login')}</p>
              <div className="tw-flex tw-mt-3 tw-justify-center tw-space-x-5">
                <div
                  onClick={onLoginByPassword}
                  className="tw-flex tw-flex-col tw-items-center tw-space-y-1 tw-cursor-pointer"
                >
                  <div className="tw-h-10 tw-w-10 tw-border tw-border-primary-main tw-rounded-full tw-flex tw-justify-center tw-items-center">
                    <LockIcon width="24" height="24" />
                  </div>
                  <span className="tw-text-[10px] tw-text-primary-main">
                    {t('login.label.password')}
                  </span>
                </div>
              </div>
            </div>
            <div className="tw-mt-12">
              <p>
                <Trans
                  t={t}
                  i18nKey="login.label.sign-up"
                  components={[
                    <Link
                      url={{ pathname: PagePath.SignUpPage }}
                      key="sign-up"
                    />,
                  ]}
                />
              </p>
            </div>
          </div>
        </section>
      </div>
      {isShow && (
        <SelectCountry
          options={countries}
          selectCountry={selectCountry}
          onClose={() => setShow(false)}
        />
      )}
    </Fragment>
  );
};

export default LoginLayout;
